import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import LaddaButton, { SLIDE_UP } from '@zumper/react-ladda';
import { fetchLogin } from '../../actions/user/login';
import { fetchSocialLogin } from '../../actions/user/socialLogin';
import { fetchVerifyUser } from '../../actions/user/verifyUser';
import { fetchResendOtp } from '../../actions/user/resendOtp';
import { displayFormErrors, loginValidations } from '../../modules/validations';
import {
	getProviderName,
	isObjectIsEmpty,
	FACEBOOK_APP_ID,
	GOOGLE_CLIENT_ID,
} from '../../modules/utils';
import CommonSocialLogin from '../common/site/Login/CommonSocialLogin';
import { Link } from 'react-router-dom';
import SocialButton from '../common/site/Button/SocialButton';
import emailSvg from "../../assets/images/images/email.svg"
import eyeSvg from "../../assets/images/images/eye.svg"
import eyeCloseSvg from "../../assets/images/images/eyeClose.svg"
import flagSvg from "../../assets/images/images/flag.svg"
import logoSvg from '../../assets/images/logo-sidebar.svg';
import "../../assets/css/login_signup/style.css"
import LoginWithApple2 from '../common/site/Login/AppleLogin2'
import OtpVerificationModal from '../common/site/OtpVerification/modal';
import { mixpostApi } from '../../mixpanel/api'
import { showToast } from '../../modules/utils';
// import bootbox from 'bootbox';
// import 'bootstrap/dist/js/bootstrap.min.js'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'jquery/dist/jquery.min.js'
import SignUpModal from './signupModal';
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			togglePassword: false,
			isOpen: false,
			otp: '',
			isEnableResendBtn: false,
			isTimerRunning: false,
			isOpenSignupModal: false
		};
		this.onHandleSubmit = this.onHandleSubmit.bind(this);
	}

	toggleSignupModal = () => {
		this.setState({ isOpenSignupModal: !this.state.isOpenSignupModal })
	}

	onHandleSubmit = async (values) => {

		this.props.fetchLogin(values).then(() => {
			const { history, loginData, loginError, error } = this.props;

			if (this.props?.loginError && this.props.loginError?.responseCode == 204) {
				this.toggleSignupModal()
				// bootbox.confirm({
				// 	title: "FieldCamp",
				// 	message: "The Email address doesn't exist in our records. Please create new account with this email.",
				// 	buttons: {
				// 		cancel: {
				// 			label: 'No',
				// 		},
				// 		confirm: {
				// 			label: 'Sign up'
				// 		}
				// 	},
				// 	callback: (result) => {
				// 		if (result) {
				// 			this.props?.history?.push({ pathname: '/register', state: { email: values.email } })
				// 		}
				// 	}
				// });
			}

			if (loginData) {
				console.log("HELLO", loginData)
				const userData =
					loginData && loginData.responseData && loginData.responseData.user
						? loginData.responseData.user
						: {};

				if (userData && !isObjectIsEmpty(userData.user_profile_details)) {
					history.push('/');
				} else {
					history.push({
						pathname: '/register',
						state: { openDiv: 'chooseType' },
					});
				}
			}
			else if (
				loginError &&
				loginError.responseData &&
				loginError.responseData.user
			) {
				const userData = loginError.responseData.user;
				console.log("HELLO---", loginError)
				if (loginError?.responseMessage == 'Need to Confirm your Account with OTP') {
					localStorage.setItem("verifyUser", true)
					localStorage.setItem("verifyToken", loginError.responseData.user.authentication_token)
					this.toggleModal()
					this.props.fetchResendOtp({})
				}
				else {
					if (userData && !isObjectIsEmpty(userData.user_profile_details)) {
						console.log(userData)
						history.push('/');
					} else {
						history.push({
							pathname: '/register',
							state: { openDiv: 'chooseType' },
						});
					}
				}
			}
		});
	};

	onSocialLoginSuccess = (user) => {
		let values = {
			provider: getProviderName(user._provider),
			access_code: user._token.accessToken,
			first_name: user._profile.firstName,
			last_name: user._profile.lastName,
			email: user._profile.email,
		};

		this.props.fetchSocialLogin(values).then(() => {
			const { socialLoginData, socialLoginError, history } = this.props;
			if (socialLoginData) {
				const userData =
					socialLoginData &&
						socialLoginData.responseData &&
						socialLoginData.responseData.user
						? socialLoginData.responseData.user
						: {};
				if (userData && !isObjectIsEmpty(userData.user_profile_details)) {
					history.push('/');
				} else {
					history.push({
						pathname: '/register',
						state: { openDiv: 'chooseType' },
					});
				}
			} else if (
				socialLoginError &&
				socialLoginError.responseData &&
				socialLoginError.responseData.user
			) {
				if (
					socialLoginError.responseData.user.is_verification_required &&
					!socialLoginError.responseData.user.is_verfied
				) {
					history.push({
						pathname: '/register',
						state: { openDiv: 'otpVerification' },
					});
				} else {
					history.push({
						pathname: '/register',
						state: { openDiv: 'chooseType' },
					});
				}
			}
		});
	};

	onSocialLoginFailure(err) {
		console.log('TCL ~ UserForm ~ onLoginFailure ~ err', err);
	}

	togglePasswordHandler = () => {
		this.setState({
			togglePassword: !this.state.togglePassword,
		});
	};

	toggleModal = () => {
		const { isOpen = false } = this.state;

		this.setState(
			{
				isOpen: !isOpen,
				isEnableResendBtn: isOpen ? true : false,
				isTimerRunning: true,
			},
			() => { },
		);
		// let eventData = DecreptionEvents();
		// if (!eventData.home_verify_tap) {
		// 	mixpostApi(decryptLoginData(), 'home_verify_tap').then((data) => {
		// 		eventData.home_verify_tap = true
		// 		setEventsData(eventData)
		// 	})
		// }

	};

	onHandleVerifyOtp = () => {
		if (!this.state.otp) {
			showToast('Please type a verification code', 0);
			return;
		}

		const values = {
			user_code: this.state.otp,
		};
		this.props.fetchVerifyUser(values).then(() => {
			const { verifyUserData } = this.props;
			if (verifyUserData) {
				!localStorage.getItem("verifyUser") && this.props.fetchProfileDetail();
				localStorage.setItem("authToken", localStorage.getItem("verifyToken"))
				// mixpostApi(decryptLoginData(), 'account_verification')
				this.props.history.push("/")
				localStorage.removeItem("verifyUser")
				localStorage.removeItem("verifyToken")
				this.toggleModal();
				// this.props.fetchProfileDetail()
			}
		});
	};

	redirectToSignup = (values) => {
		console.log(values)
	}

	resendOtp = () => {
		this.props.fetchResendOtp({}).then(() => {
			const { resendOtpData } = this.props;
			if (resendOtpData) {
				this.setState({ isEnableResendBtn: false, isTimerRunning: true });
			}
		});
	};

	handleOtpChange = (otp) => this.setState({ otp });

	onCompleteTimer = () => {
		this.setState({ isEnableResendBtn: true, isTimerRunning: false });
	};

	render() {
		const { history, loginLoading = false } = this.props;
		const { togglePassword, isOpen, otp, isEnableResendBtn, isTimerRunning } = this.state;

		return (
			<div className="wrapper-login">
				<div className="d-flex justify-content-center flex-wrap">
					<div className="col-lg-6 login-content">
						<div >
							<Link to='/' title="FieldCamp" className="logo"><img src={logoSvg} alt="FieldCamp" /></Link>
							<div className="login-box">
								<div className="d-flex justify-content-between align-items-center login-header">
									<div className="login-title">
										Sign In
									</div>
{/* 									<p><span className="">Don't have an account?</span> <Link to="/register" title="Login" className="swap-link">Signup</Link></p>
 */}								</div>

								<Formik
									initialValues={{
										email: '',
										password: '',
									}}
									validationSchema={loginValidations()}
									onSubmit={this.onHandleSubmit}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										submitCount,
									}) => {
										const showError = (key) =>
											displayFormErrors(key, errors, touched, submitCount);
										return (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label htmlFor="email">Email</label>
															<div className="input-wrap">
																<span><img src={emailSvg} alt="Email" /></span>
																<input
																	className={errors.email ? "invalidFormInput form-control" : "form-control"}
																	type="text"
																	name="email"
																	placeholder="admin@mybusiness.com"
																	value={values.email}
																	onChange={handleChange}
																	onBlur={handleBlur}
																/>
																{showError('email')}
															</div>
														</div>
													</div>
													<div className="col-md-12">
														<div className="form-group">
															<label htmlFor="password">Password</label>
															<div className="input-wrap">
																<span><img src={togglePassword ? eyeSvg : eyeCloseSvg} className={!togglePassword && "mt-1"} alt="Eye" onClick={this.togglePasswordHandler} /></span>
																<input
																	type={togglePassword ? 'text' : 'password'}
																	name="password"
																	className={errors.password ? "invalidFormInput form-control" : "form-control"}
																	placeholder="********"
																	value={values.password}
																	onChange={handleChange}
																	onBlur={handleBlur}
																/>
																{/* <i
																			className={
																				togglePassword
																					? 'fa fa-eye-slash field-icon'
																					: 'fa fa-eye field-icon'
																			}
																			aria-hidden="true"
																			onClick={this.togglePasswordHandler}
																		></i> */}
																{showError('password')}
															</div>
															<Link to={`/forgot-password`} title="Forgot Password?" className="float-right forgot-link d-hide">
																Forgot Password?
															</Link>
															{/* <a href="#" title="Forgot Password?" className="float-right forgot-link d-hide">Forgot Password?</a> */}
														</div>
													</div>
													<div className="col-md-12 d-show">
														<div className="form-group">
															<label>Phone Number</label>
															<div className="input-group-outer">
																<div className="input-group">
																	<div className="input-group-prepend">
																		<span className="input-group-text"><img src={flagSvg} alt="Flag" /></span>
																	</div>
																	<input type="text" className="form-control" value="+1" />
																</div>
																<input type="tel" className="form-control" placeholder="(202) 555-555" />
															</div>
														</div>
													</div>
													<div className="col-md-12">
														<div className="bottom-block">
															<LaddaButton
																type="submit"
																className="btn btn-blue"
																loading={loginLoading}
																style={SLIDE_UP}
																spinnerSize={20}
															>
																Sign in
															</LaddaButton>
															{/* <button type="btn" className="btn btn-blue">Sign In</button> */}
															<p className="d-show">By signing up, you agree to the <a href="#" title="Terms of Use">Terms of Use</a> & <a href="#" title="Privacy Policy">Privacy Policy</a></p>
															<div className="or-block"><span>OR</span></div>
															{/* <button type="btn" className="btn btn-google"><i><img src="images/google-icon.svg" alt="Google"></i>Continue with Google</button> */}
															<SocialButton
																provider="google"
																appId={GOOGLE_CLIENT_ID}
																onLoginSuccess={this.onSocialLoginSuccess}
																onLoginFailure={this.onSocialLoginFailure}
																key={'google'}
																className="btn btn-google btn-block google_btn f-family-text"
																debug
																scope={'https://www.googleapis.com/auth/user.gender.read'}
															>
																<i className="icon"></i> Continue with Google
															</SocialButton>
															<LoginWithApple2 />
															{/* <button type="btn" className="btn btn-google"><i><img src="images/google-icon.svg" alt="Google"/></i>Continue with Google</button> */}
														</div>
													</div>
												</div>
												{
													this.state.isOpenSignupModal &&
													<SignUpModal
														toggle={this.toggleSignupModal}
														isOpen={this.state.isOpenSignupModal}
														values={values}
														pushLoc={this.props.history}
													/>
												}
											</form>
										);
									}}
								</Formik>

							</div>
						</div>
					</div>
					<div className="col-lg-6 pd-0 bg-image-block">
					</div>
				</div>
				{
					<OtpVerificationModal
						otp={otp}
						userData={this.props?.loginError?.responseData?.user}
						toggle={this.toggleModal}
						isOpen={isOpen}
						verifyUserLoading={this.props.verifyUserLoading}
						onHandleVerifyOtp={this.onHandleVerifyOtp}
						resendOtp={this.resendOtp}
						handleOtpChange={this.handleOtpChange}
						onCompleteTimer={this.onCompleteTimer}
						isEnableResendBtn={isEnableResendBtn}
						isTimerRunning={isTimerRunning}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loginLoading: state.login.isLoading,
		loginData: state.login.data,
		loginError: state.login.error,

		socialLoginLoading: state.socialLogin.isLoading,
		socialLoginData: state.socialLogin.data,
		socialLoginError: state.socialLogin.error,

		verifyUserLoading: state.verifyUser.isLoading,
		verifyUserData: state.verifyUser.data,

		resendOtpLoading: state.resendOtp.isLoading,
		resendOtpData: state.resendOtp.data,
	};
};

const mapDispatchToProps = {
	fetchLogin,
	fetchSocialLogin,
	fetchVerifyUser,
	fetchResendOtp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
