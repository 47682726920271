import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import Blog from './components/blog';
import Faq from './components/faq';
import Services from './components/services';
import Features from './components/features';
import Home from './components/home';

import Login from './components/login';
import Register from './components/register';

import partyReturn from './components/common/site/Login/PartyReturn'

import ForgetPassword from './components/forgotPassword';
import AboutUs from './pages/AboutUs';
import ScrollToTop from './modules/ScrollToTop';
import * as CryptoJS from "crypto-js";
import { FastField } from 'formik';
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const AuthRoute = ({ component: Component, ...rest }) => {
	const authToken = localStorage.getItem('authToken');

	if (authToken && !sessionStorage.getItem("events")) {
		const events = {
			create_job_select_customer_tap: false,
			create_job_assign_to_tap: false,

			create_job_select_category_tap: false,
			create_job_add_line_item: false,
			create_job_discount: false,
			create_job_tax: false,

			create_job_repeat_options_tap: false,
			create_job_time_tap: false,
			create_job_date_tap: false,

			create_job_button_tap: false,

			staff_tab_event_tap: false,
			staff_plus_button_tap: false,
			create_staff_contact_tap: false,
			create_staff_expertise: false,
			create_staff_identify_proof: false,
			create_staff_button_tap: false,

			jobs_date_filter_tap: false,
			jobs_filter_tap: false,
			jobs_add_button_tap: false,

			create_service_currency_change_tap: false,
			create_service_add_button_tap: false,

			company_edit_tap: false,
			customer_edit_tap: false,

			setting_change_password: false,
			setting_date_format: false,
			setting_time_format: false,
			setting_currency: false,

			setting_tax_rates: false,
			setting_payment_gatways: false,
			setting_service_terms: false,

			create_customer_contact_tap: false,
			create_customer_button_tap: false,

			schedule_staff_filter: false,
			schedule_job_filter: false,
			schedule_month_dialog: false,
			schedule_view_filter: false,

			stripe_connect: false,

			customer_add_tap: false,

			dashboard_create_job_tap: false,

			select_property_add_tap: false,

			dashboard_month_dialog: false,
			setting_edit_profile: false,
			setting_jobs: false,
			home_verify_tap: false,
		}
		sessionStorage.setItem("events", CryptoJS.AES.encrypt(JSON.stringify(events), "fieldCampLogin"))
	} else if (!authToken && !sessionStorage.getItem("event")) {
		const event = {
			sign_up_create_account_tap: false,
			sign_up_api_error: false,
		}

		sessionStorage.setItem("event", CryptoJS.AES.encrypt(JSON.stringify(event), "fieldCampLogin"))
	}


	const redirectUrl = process.env.REACT_APP_UNAUTHORISED_USER_REDIRECT_SERVER; //REACT_APP_UNAUTHORISED_USER_REDIRECT_SERVER
	// console.log('CK ~ AuthRoute ~ redirectUrl', redirectUrl);
	return (

		<Route
			{...rest}
			render={(props) =>
				authToken ? (
					<TheLayout {...props} />
				) : (
					<Redirect to={redirectUrl} /> //local
				)
			}
		/>
	);
};

class Routes extends Component {

	render() {

		return (
			<Router basename={process.env.PUBLIC_URL}>
				<ScrollToTop />
				<React.Suspense fallback={loading}>
					<Switch>
						{/* <Route exact name="Blog" path="/blog" component={Blog} /> */}
						{/* <Route exact name="Faq" path="/faq" component={Faq} /> */}
						{/* <Route
							exact
							name="Services"
							path="/services"
							component={Services}
						/> */}
						{/* <Route
							exact
							name="Features"
							path="/features"
							component={Features}
						/> */}
						{/* <Route exact name="Pricing" path="/pricing" component={Features} /> */}
						{/* <Route exact name="Home" path="/home" component={Home} /> */}
						<Route exact name="apple" path="/party-return" component={partyReturn} />

						{localStorage.getItem("authToken") ? <Route exact path="/login" render={() => <Redirect to="/" />} /> : <Route exact name="Login" path="/login" component={Login} />}
{/* 						<Route
							exact
							name="Register"
							path="/register"
							component={Register}
						/> */}
						<Route
							exact
							name="Forgot Password"
							path="/forgot-password"
							component={ForgetPassword}
						/>
						<AuthRoute
							path="/"
							name="Home"
							render={(props) => <TheLayout {...props} />}
						/>

						<Route exact path="/" render={() => <Redirect to="/login" />} />
					</Switch>
				</React.Suspense>
			</Router>
		);
	}
}

export default Routes;
